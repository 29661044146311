import React from "react";
import { level2Label } from "./app/characters-slice";

import classnames from "classnames";

import "./Skill.css";

export default function Skill({ skillName, numericSkillLevel, trappings }) {
  return (
    <div
      className={classnames([
        "Skill",
        // `Skill--${level2Label(numericSkillLevel)}`,
      ])}
      data-description={`${trappings.join(", ")}`}
    >
      <span
        className={classnames([
          "SkillName",
          `SkillName--${level2Label(numericSkillLevel)}`,
        ])}
      >
        {skillName}
      </span>
      <span
        className={classnames([
          "SkillLabelLevel",
          `SkillLabelLevel--${level2Label(numericSkillLevel)}`,
        ])}
      >{`${level2Label(numericSkillLevel)} (${numericSkillLevel})`}</span>
    </div>
  );
}
