import * as R from "ramda";

const baseUrl =
  "https://v2-api.sheety.co/978097005415f5d3b1fcd0c3a112c2c1/bobTheSkull";

const collections = [
  "Characters",
  "Character Aspects",
  "Character Stress",
  "Character Consequences",
  "Consequence Levels",
  "Character Skills",
  "Skills",
  "Skills Trappings",
  "Character Stunts",
  "Stunts",
  "Character Powers",
  "Powers",
  "Book References",
  "Wizard Items",
  "Spellcaster Rotes",
];

function setCharAt(str, index, chr) {
  if (index > str.length - 1) return str;
  return str.substr(0, index) + chr + str.substr(index + 1);
}

function snakeCase(phrase) {
  // converts human-written text to snakeCase.
  let result = phrase.split(" ");
  result[0] = setCharAt(result[0], 0, result[0][0].toLowerCase());
  result = result.join("");
  return result;
}

function collectionNameToEndpoint(collectionName) {
  return `${baseUrl}/${snakeCase(collectionName)}`;
}

const endpoints = collections.map(collectionNameToEndpoint);

const endpointMap = R.zipObj(collections, endpoints);

function createModel(collectionName) {
  function handleResponseForMany(response) {
    if (response.status !== 200) {
      throw new Error("Not a 200 when fetching " + collectionName);
    }
    return response
      .json()
      .then((response) => response[snakeCase(collectionName)]);
  }

  function flattenObj(object) {
    return object[Object.keys(object)[0]];
  }

  function handleResponseForOne(response) {
    if (response.status === 204) {
      return true;
    }
    if (response.status !== 200) {
      throw new Error("Not a 200 when fetching " + collectionName);
    }
    try {
      return response.json().then((data) => flattenObj(data));
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  const handleError = (method = "get") => (response) => {
    throw new Error(`Error when ${method} ${collectionName}`);
  };

  async function _getAll() {
    return await fetch(endpointMap[collectionName])
      .then(handleResponseForMany)
      .catch(handleError());
  }
  async function _getByFilter(filter) {
    console.log("Filtering: ", filter);
    // filter is object w/ keys as filter names and values as searched values
    const query = Object.keys(filter)
      .map((key) => `${key}=${filter[key]}`)
      .join("&");
    return await fetch(`${endpointMap[collectionName]}?${query}`)
      .then(handleResponseForMany)
      .catch(handleError());
  }
  async function _getById(id) {
    return await fetch(`${endpointMap[collectionName]}/${id}`)
      .then(handleResponseForOne)
      .catch(handleError());
  }
  async function _deleteById(id) {
    return await fetch(`${endpointMap[collectionName]}/${id}`, {
      method: "delete",
    })
      .then(handleResponseForOne)
      .catch(handleError("delete"));
  }
  async function _delete() {
    return await fetch(`${endpointMap[collectionName]}`, {
      method: "delete",
    })
      .then(handleResponseForOne)
      .catch(handleError("delete"));
  }
  async function _post(payload) {
    return await fetch(`${endpointMap[collectionName]}`, {
      method: "post",
      body: JSON.stringify({ [snakeCase(collectionName)]: payload }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleResponseForOne)
      .catch(handleError("post"));
  }
  async function _putById(id, payload) {
    return await fetch(`${endpointMap[collectionName]}/${id}`, {
      method: "put",
      body: JSON.stringify({ [snakeCase(collectionName)]: payload }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleResponseForOne)
      .catch(handleError("put"));
  }
  return {
    getAll: _getAll,
    getByFilter: _getByFilter,
    getById: _getById,
    deleteById: _deleteById,
    delete: _delete,
    post: _post,
    putById: _putById,
  };
}

export default R.converge(R.zipObj, [R.identity, R.map(createModel)])(
  collections
);
