import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { loggedInUserSelector, logout, login } from "./app/auth-slice";

export default function HeaderProfileButton() {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(loggedInUserSelector);

  return loggedInUser == null ? (
    <div className="clickable" onClick={() => dispatch(login)}>
      Login
    </div>
  ) : (
    <div className="clickable" onClick={() => dispatch(logout)}>
      Logout
    </div>
  );
}
