import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  loadCharacterStarted,
  loadCharacterFulfilled,
} from "./app/characters-slice";

import { getCharacterByName } from "./bob-the-skull-api/queries";

import Loading from "./Loading";

export default function withCharacterData(WrappedComponent) {
  return function HasCharacterData() {
    const dispatch = useDispatch();
    const { characterName } = useParams();
    const characterState = useSelector(
      (state) => state.characters[characterName]
    );
    useEffect(() => {
      dispatch(loadCharacterStarted(characterName));
      getCharacterByName(characterName).then((character) => {
        dispatch(loadCharacterFulfilled(character));
      });
    }, [dispatch, characterName]);

    return !characterState || characterState.loading ? (
      <Loading />
    ) : (
      <WrappedComponent characterState={characterState} />
    );
  };
}
