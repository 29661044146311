import React from "react";

import HeaderProfileButton from "./HeaderProfileButton";

import "./WithHeader.css";

export default function WithHeader({ path, name, children }) {
  return (
    <>
      <header className="AppHeader">
        <div className="AppHeader__Left">BOB THE SKULL</div>
        <div className="AppHeader__Right">
          {path} -- {name}
        </div>
        <div className="AppHeader__Auth">
          <HeaderProfileButton />
        </div>
      </header>
      {children}
    </>
  );
}
