import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import * as firebase from "firebase/app";
import "firebase/auth";

var provider = new firebase.auth.GoogleAuthProvider();

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {},
    loggedIn: false,
  },
  reducers: {
    setLoggedInUser(state, action) {
      const {
        uid,
        email,
        emailVerified,
        displayName,
        photoURL,
        refreshToken,
      } = action.payload;
      state.user = {
        uid,
        email,
        emailVerified,
        displayName,
        photoURL,
        refreshToken,
      };
      state.loggedIn = true;
    },
    logOutUser(state, action) {
      state.user = null;
      state.loggedIn = false;
    },
  },
});

export const getLoggedInUser = (authState) =>
  authState.loggedIn ? authState.user : null;
export const authSelector = (state) => state.auth;
export const loggedInUserSelector = createSelector(
  authSelector,
  getLoggedInUser
);

export const login = (dispatch) => {
  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(function () {
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(function (result) {
          window.user = result.user;
          const {
            uid,
            email,
            emailVerified,
            displayName,
            photoURL,
            refreshToken,
          } = result.user;
          dispatch(
            authSlice.actions.setLoggedInUser({
              uid,
              email,
              emailVerified,
              displayName,
              photoURL,
              refreshToken,
            })
          );
        })
        .catch(function (error) {
          // Handle Errors here.
          window.errorCode = error.code;
          window.errorMessage = error.message;
          // The email of the user's account used.
          window.email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          window.credential = error.credential;
          // ...
        });
    });
};

export const logout = (dispatch) => {
  firebase
    .auth()
    .signOut()
    .then(function (result) {
      dispatch(authSlice.actions.logOutUser());
    })
    .catch(function (error) {
      // Handle Errors here.
      window.errorCode = error.code;
      window.errorMessage = error.message;
      // The email of the user's account used.
      window.email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      window.credential = error.credential;
      // ...
    });
};

export const { setLoggedInUser } = authSlice.actions;
export default authSlice.reducer;
