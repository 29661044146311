import { createSlice } from "@reduxjs/toolkit";
import {
  loadCharactersStarted,
  loadCharactersFulfilled,
} from "./characters-slice";

const loadingSlice = createSlice({
  name: "loading",
  initialState: true,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadCharactersStarted, (state, action) => {
      return true;
    });
    builder.addCase(loadCharactersFulfilled, (state, action) => {
      return false;
    });
  },
});

export default loadingSlice.reducer;
