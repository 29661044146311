import React from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import * as R from "ramda";

import { healStress, damageStress } from "./app/characters-slice";

import "./StressBoxes.css";

export default function StressBoxes({ character, stressTrackName }) {
  const dispatch = useDispatch();

  const removeStressBox = R.compose(dispatch, healStress);
  const addStressBox = R.compose(dispatch, damageStress);
  return (
    <div className="StressBoxes">
      {R.valuesIn(character.stress[stressTrackName])
        .sort((a, b) => a.stressLevelTaken - b.stressLevelTaken)
        .map(({ stressLevelTaken, type, filled, id, network }) => {
          return (
            <div
              key={`${character.characterName}-${stressTrackName}-${stressLevelTaken}`}
              className={classnames([
                "StressBoxes__Box",
                "clickable",
                {
                  "StressBoxes__Box--empty": !filled,
                  "StressBoxes__Box--supernatural": type === "Supernatural",
                  "StressBoxes__Box--full": filled,
                  "StressBoxes__Box--pending": network === "Pending",
                  "StressBoxes__Box--rejected": network === "Rejected",
                },
              ])}
              onClick={(e) =>
                character.stress[stressTrackName][stressLevelTaken].network ===
                "Pending"
                  ? null
                  : character.stress[stressTrackName][stressLevelTaken].filled
                  ? removeStressBox({
                      characterName: character.characterName,
                      stressTrackName,
                      stressLevelTaken,
                      id,
                    })
                  : addStressBox({
                      characterName: character.characterName,
                      stressTrackName,
                      stressLevelTaken,
                    })
              }
            >
              {stressLevelTaken}
            </div>
          );
        })}
    </div>
  );
}
