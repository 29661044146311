import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import { memoize } from 'lodash';

import { getPowersDefinitions as _getPowersDefinitions, getSkillDefinitions as _getSkillDefinitions, getStuntDefinitions as _getStuntDefinitions } from '../bob-the-skull-api/queries';

const getPowersDefinitions = memoize(_getPowersDefinitions);
const getSkillDefinitions = memoize(_getSkillDefinitions);
const getStuntDefinitions = memoize(_getStuntDefinitions);

export const loadCompendium = createAsyncThunk(
  "characters/loadCompendium",
  async (payload, thunkAPI) => {
    const powers = await getPowersDefinitions(payload);
    const skills = await getSkillDefinitions(payload);
    const stunts = await getStuntDefinitions(payload);
    return {
      powers,
      skills,
      stunts,
    }
  }
);


const compendiumSlice = createSlice({
  name: "compendium",
  initialState: {
    loading: true,
  },
  reducers: {
  },
  extraReducers: {
    [loadCompendium.pending]: (state, action) => {
      state.loading = true;
    },
    [loadCompendium.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        powers,
        skills,
        stunts,
      } = action.payload;
      state.powers = powers;
      state.skills = skills.skills;
      state.trappings = skills.trappings
      state.stunts = stunts;
    },
    [loadCompendium.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
    },
  }
});

export const getCompendiumData = state => state.compendium;
export const getPowersData = createSelector(
  getCompendiumData,
  state => state.powers
)
export const getStuntData = createSelector(
  getCompendiumData,
  state => state.stunts
)
export const getSkillData = createSelector(
  getCompendiumData,
  state => state.skills
)



export default compendiumSlice.reducer;