import React from "react";
import { useDispatch } from "react-redux";
import * as R from "ramda";

import { spendFate } from "./app/characters-slice";

import CharacterAspects from "./CharacterAspects";
import Rotes from "./Rotes";
import StressBoxes from "./StressBoxes";
import Skill from "./Skill";
import WizardItems from "./WizardItems";

import "./CharacterSheet.css";

function sum(accumulator, current) {
  return accumulator + current;
}

export default function CharacterSheet({ character }) {
  const dispatch = useDispatch();

  const {
    characterName,
    skills,
    stunts,
    powers,
    wizardItems,
    rotes,
  } = character;

  const refreshSpent = powers
    .map(({ refreshCost }) => refreshCost)
    .reduce(sum, 0);
  const refreshLevel = character.refreshCap + refreshSpent;

  return (
    <div className="CharacterSheet">
      <h1>{characterName}</h1>
      <CharacterAspects characterState={character} />
      <div className="CharacterSheet__Refresh">
        <h2>Refresh and Milestones</h2>
        <div className="CharacterSheet__RefreshLine">
          <span>Refresh Cap</span>
          <span>{character.refreshCap}</span>
        </div>
        <div className="CharacterSheet__RefreshLine">
          <span>Refresh Spent</span>
          <span>{refreshSpent}</span>
        </div>
        <div className="CharacterSheet__RefreshLine">
          <span>Refresh Level</span>
          <span>{refreshLevel}</span>
        </div>
        <div className="CharacterSheet__RefreshLine">
          <span>Fate Points</span>
          <span>{character.fatePoints}</span>
        </div>
        <button
          className="CharacterSheet__SpendFateButton"
          onClick={(e) => {
            R.compose(dispatch, spendFate)({ characterName });
          }}
        >
          SPEND FATE
        </button>
        <div className="CharacterSheet__RefreshLine">
          <span>Skill Cap</span>
          <span>{character.skillCap}</span>
        </div>
      </div>
      <div className="CharacterSheet__Skills">
        <h2>Skills</h2>
        {skills.map((skill) => (
          <div key={skill.id} className="CharacterSheet__Skill">
            <Skill {...skill} />
          </div>
        ))}
      </div>
      <div className="CharacterSheet__Notes">
        <h2>Additional Notes</h2>
        <p>{character.additionalNotes}</p>
      </div>
      <div className="CharacterSheet__Health">
        <h2>Health</h2>
        <h3>Stress</h3>
        <h4>Physical</h4>
        <StressBoxes character={character} stressTrackName="Physical" />
        <h4>Mental</h4>
        <StressBoxes character={character} stressTrackName="Mental" />
        <h4>Social</h4>
        <StressBoxes character={character} stressTrackName="Social" />
        <h3>Consequences</h3>
      </div>
      <div className="CharacterSheet__Stunts">
        <h2>Stunts</h2>
        {stunts.map(({ stuntName, id }) => (
          <p key={id}>{stuntName}</p>
        ))}
      </div>
      <div className="CharacterSheet__Powers">
        <h2>Powers</h2>
        {powers.map(({ id, powerName, refreshCost, notes }) => (
          <p key={id} className="CharacterSheet__Power">
            <span className="CharacterSheet__PowerName">{`${powerName} (${refreshCost})`}</span>
            <span className="CharacterSheet__PowerNotes">{notes}</span>
          </p>
        ))}
      </div>
      <div className="CharacterSheet__WizardItems">
        {wizardItems.length ? (
          <>
            <h2>Magic Items</h2>
            <WizardItems wizardItems={wizardItems} />
          </>
        ) : null}
      </div>
      <div className="CharacterSheet__Rotes">
        {rotes.length ? (
          <>
            <h2>Rotes</h2>
            <Rotes rotes={rotes} />
          </>
        ) : null}
      </div>
    </div>
  );
}
