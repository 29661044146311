import * as firebase from "firebase/app";

import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyDu2TgbIiEigjQ-D60LYKJw73C3dHdwyJ4",
  authDomain: "bob-the-skull-f9586.firebaseapp.com",
  databaseURL: "https://bob-the-skull-f9586.firebaseio.com",
  projectId: "bob-the-skull-f9586",
  storageBucket: "bob-the-skull-f9586.appspot.com",
  messagingSenderId: "388737975995",
  appId: "1:388737975995:web:538ce5a57ef7d808e24b9e",
  measurementId: "G-N9FEDKMTN7",
};

var fire = firebase.initializeApp(firebaseConfig);

export default fire;
