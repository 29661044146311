import React from "react";
import classnames from "classnames";

import { highConceptSelector } from "./app/characters-slice";

import "./CharacterStub.css";

export default function CharacterStub({ onClick, character }) {
  const { characterName, refreshCap, profileImageUrl } = character;
  const style = profileImageUrl
    ? {
        backgroundImage: `url(${profileImageUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left center",
        backgroundSize: "cover",
      }
    : {};
  return (
    <div>
      <div
        style={style}
        className={classnames([
          "characterStub",
          { clickable: [onClick], "characterStub--hasImage": profileImageUrl },
        ])}
        onClick={onClick}
      >
        <div className="characterStub__name">
          <div className="characterStub__name--number">{characterName}</div>
          <div className="characterStub__name--caption">name</div>
        </div>
        <div className="characterStub__highConcept">
          <div className="characterStub__highConcept--number">
            {highConceptSelector(character).aspectName}
          </div>
          <div className="characterStub__highConcept--caption">
            high concept
          </div>
        </div>
        <div className="characterStub__refreshCap">
          <div className="characterStub__refreshCap--number">{refreshCap}</div>
          <div className="characterStub__refreshCap--caption">refresh cap</div>
        </div>
      </div>
    </div>
  );
}
