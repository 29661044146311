import React from "react";

import CharacterAspects from "./CharacterAspects";

export default function CharacterAspectsPage({ characterState }) {
  return (
    <>
      <h1>{characterState.characterName}</h1>
      <CharacterAspects characterState={characterState} />
    </>
  );
}
