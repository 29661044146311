import * as P from "bluebird";
import * as R from "ramda";
import _ from "lodash";
import models from "./database";

// Queries are for domain objects and don't care how things are stored in the database or how they are related to one another.
// They also make the data pretty for the front end.

// database -> models -> normalization -> business logic -> decoration -> front end

const WAIT_MS = 50;

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function toObjectByCharacterName(accumulator, current) {
  return {
    ...accumulator,
    [current.characterName]: current,
  };
}

export async function getCharacters() {
  const charactersDocuments = await models.Characters.getAll();
  const result = await P.map(
    charactersDocuments,
    async ({ characterName }) => {
      await sleep(WAIT_MS);
      return getCharacterByName(characterName);
    },
    { concurrency: 1 }
  );

  return R.reduce(toObjectByCharacterName, {}, result);
}

export async function getCharacterById(characterId) {
  const characterDoc = await models.Characters.getById(characterId);
  return characterDoc;
}

export async function getCharacterByName(characterName) {
  let characterDoc = await models.Characters.getByFilter({
    characterName,
  });
  characterDoc = characterDoc[0];
  let [
    characterStressDocs,
    characterSkillsDocs,
    characterStuntsDocs,
    characterPowersDocs,
    characterWizardItemsDocs,
    characterRotesDocs,
    characterAspectDocs,
  ] = await P.map(
    [
      models["Character Stress"],
      models["Character Skills"],
      models["Character Stunts"],
      models["Character Powers"],
      models["Wizard Items"],
      models["Spellcaster Rotes"],
      models["Character Aspects"],
    ],
    async (model) => {
      await sleep(WAIT_MS);
      return model.getByFilter({
        characterName,
      });
    },
    { concurrency: 1 }
  );

  function getMaxStressByType(stressTrackName) {
    let skillName = "";
    switch (stressTrackName) {
      case "Physical":
        skillName = "Endurance";
        break;
      case "Mental":
        skillName = "Conviction";
        break;
      case "Social":
        skillName = "Presence";
        break;
      case "Hunger":
        skillName = "Discipline";
        break;
      default:
        throw new Error("Stress track not valid.");
    }

    return maxStressForSkillLevel(getSkill(skillName));
  }

  function getSkill(skillName) {
    // todo: validate skillName is a real skill
    let skill = characterSkillsDocs.find(
      (skillObj) => skillObj.skillName === skillName
    );
    if (skill) return skill.numericSkillLevel;
    return 0;
  }

  function maxStressForSkillLevel(numericSkillLevel) {
    if (numericSkillLevel <= 0) return 2;
    if (numericSkillLevel <= 2) return 3;
    return 4;
  }

  function physicalStressFromPowers() {
    if (
      characterPowersDocs.find(
        (power) => power.powerName === "Inhuman Toughness"
      )
    ) {
      return 2;
    } else if (
      characterPowersDocs.find(
        (power) => power.powerName === "Supernatural Toughness"
      )
    ) {
      return 4;
    } else if (
      characterPowersDocs.find(
        (power) => power.powerName === "Mythic Toughness"
      )
    ) {
      return 6;
    }
    return 0;
  }

  function initializeStress(stressTrackName) {
    let maxStress =
      getMaxStressByType(stressTrackName) +
      (stressTrackName === "Physical" ? physicalStressFromPowers() : 0);

    return R.range(0, maxStress).map((el) => ({
      stressLevelTaken: el + 1,
      characterName,
      stressTrackName,
      filled: false,
      type:
        el + 1 > getMaxStressByType(stressTrackName)
          ? "Supernatural"
          : "Normal",
    }));
  }

  function toObj(accumulator, current) {
    return { ...accumulator, [current.stressLevelTaken]: current };
  }

  characterStressDocs = characterStressDocs.filter(
    (stressDoc) => stressDoc.healed !== true
  );

  characterStressDocs = characterStressDocs.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [current.stressTrackName]: {
        ...accumulator[current.stressTrackName],
        [current.stressLevelTaken]: { ...current, filled: true },
      },
    }),
    {
      Physical: R.reduce(toObj, {}, initializeStress("Physical")),
      Mental: R.reduce(toObj, {}, initializeStress("Mental")),
      Social: R.reduce(toObj, {}, initializeStress("Social")),
      Hunger: R.reduce(toObj, {}, initializeStress("Hunger")),
    }
  );

  const getTrappings = _.memoize(async (skillName) => {
    await sleep(WAIT_MS);
    return models["Skills Trappings"].getByFilter({
      skillName,
    });
  });

  let skillTrappingsDocs = await P.map(
    characterSkillsDocs,
    ({ skillName }) => getTrappings(skillName),
    {
      concurrency: 1,
    }
  );

  characterSkillsDocs = characterSkillsDocs.map((skillsDoc, index) => ({
    ...skillsDoc,
    trappings: skillTrappingsDocs[index].map(
      (trapping) => trapping.trappingName
    ),
  }));

  const getPowers = _.memoize(async (powerName) => {
    await sleep(WAIT_MS);
    return models["Powers"].getByFilter({ powerName });
  });

  if (characterPowersDocs.length) {
    let powerDefinitions = await P.map(
      characterPowersDocs,
      ({ powerName }) => getPowers(powerName),
      {
        concurrency: 1,
      }
    ).map((powerDefinition) => powerDefinition[0]);
    characterPowersDocs = characterPowersDocs.map((doc, index) => ({
      ...doc,
      ...powerDefinitions[index],
      refreshCost:
        doc.refreshCostOverride || powerDefinitions[index].refreshCost || 0,
    }));
  }

  return {
    ...characterDoc,
    stress: characterStressDocs,
    skills: characterSkillsDocs,
    stunts: characterStuntsDocs,
    powers: characterPowersDocs,
    wizardItems: characterWizardItemsDocs,
    rotes: characterRotesDocs,
    aspects: characterAspectDocs.reduce((accumulator, currentValue) => {
      return { ...accumulator, [currentValue.aspectName]: currentValue };
    }, {}),
  };
}


export async function getPowersDefinitions() {
  return await models.Powers.getAll()
}

export async function getSkillDefinitions() {
  const [skills, trappings] = await P.all([models.Skills.getAll(), models["Skills Trappings"].getAll()]);
  return {
    skills,
    trappings
  }
}

export async function getStuntDefinitions() {
  return await models.Stunts.getAll();
}
