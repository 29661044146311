import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';

import { Link, useLocation } from "react-router-dom";

import {
  getCompendiumData
} from './app/compendium-slice'

import './Compendium.css'


export default function Compendium() {
  const state = useSelector(getCompendiumData);
  const location = useLocation();

  let focus;

  switch(true) {
    case state.loading:
      focus = null;
    break;
    case location.pathname.includes("powers"):
    const sortByPowerCategory = R.sortBy(R.compose(R.toLower, R.prop('powerCategory')));
    const powerCategories = R.uniq(R.map(R.prop('powerCategory'), sortByPowerCategory(state.powers)));
    const powersInCategory = powerCategory => R.filter(power => power.powerCategory === powerCategory, state.powers)
    focus = <div className="Compendium__Focus"><div><h1><Link to="/compendium/powers">Powers</Link></h1>{powerCategories
      .map(powerCategory => <div><h3>{powerCategory}</h3>{powersInCategory(powerCategory).map(
        power => <p><Link to={`/compendium/powers/${power.powerName}`}>{power.powerName}</Link></p>
      )}</div>)}</div></div>
    break;
    case location.pathname.includes("skills"):
    focus = <div className="Compendium__Focus"><div><h1><Link to ="/compendium/skills">Skills</Link></h1>{state.skills.map(
        skill => <p><Link to={`/compendium/skills/${skill.skillName}`}>{skill.skillName}</Link></p>
      )}</div></div>
    break;
    case location.pathname.includes("stunts"):
    focus = <div className="Compendium__Focus"><div> <h1><Link to ="/compendium/stunts">Stunts</Link></h1>{state.stunts.map(
        stunt => <p><Link to={`/compendium/stunts/${stunt.stuntName}`}>{stunt.stuntName}</Link></p>
      )}</div></div>
    break;
    case location.pathname.includes("trappings"):
    focus = <div className="Compendium__Focus"><div><h1><Link to ="/compendium/trappings">Trappings</Link></h1>{state.trappings.map(
        trapping => <p><Link to={`/compendium/trappings/${trapping.trappingName}`}>{trapping.trappingName}</Link></p>
      )}</div></div>
    break;
    default:
    focus = null;
    break;
  }
  return <div>
      {focus}
  <div className="Compendium__Main">
      {state.powers ? <div><h1><Link to="/compendium/powers">Powers</Link></h1>{state.powers.map(
        power => <p><Link to={`/compendium/powers/${power.powerName}`}>{power.powerName}</Link></p>
      )}</div> : null}
      {state.skills ? <div><h1><Link to ="/compendium/skills">Skills</Link></h1>{state.skills.map(
        skill => <p><Link to={`/compendium/skills/${skill.skillName}`}>{skill.skillName}</Link></p>
      )}</div> : null }
      {state.stunts ? <div> <h1><Link to ="/compendium/stunts">Stunts</Link></h1>{state.stunts.map(
        stunt => <p><Link to={`/compendium/stunts/${stunt.stuntName}`}>{stunt.stuntName}</Link></p>
      )}</div> : null }
      {state.trappings ? <div><h1><Link to ="/compendium/trappings">Trappings</Link></h1>{state.trappings.map(
        trapping => <p><Link to={`/compendium/trappings/${trapping.trappingName}`}>{trapping.trappingName}</Link></p>
      )}</div> : null }
    </div></div>;
}