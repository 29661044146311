import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function CharacterAspectExplanation() {
  const { characterName, aspectName } = useParams();
  const aspect = useSelector(
    (state) => state.characters[characterName].aspects[aspectName]
  );

  return (
    <div>
      <h1>{characterName}</h1>
      <h2>{aspect.aspectName}</h2>
      <h3>{aspect.aspectType}</h3>
      <p>{aspect.howYouGotIt}</p>
    </div>
  );
}
