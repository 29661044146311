import React from "react";
import { Link } from "react-router-dom";

import {
  highConceptSelector,
  troubleSelector,
  backgroundAspectsSelector,
} from "./app/characters-slice";

export default function CharacterAspects({ characterState }) {
  const { characterName } = characterState;

  const highConcept = highConceptSelector(characterState);
  const trouble = troubleSelector(characterState);
  const backgroundAspects = backgroundAspectsSelector(characterState);

  return (
    <div className="CharacterSheet__Aspects">
      <h2>
        <Link to={`/character/${characterName}/aspects`}>Aspects</Link>
      </h2>
      <h3>High Concept</h3>
      <div>
        <Link
          to={`/character/${characterName}/aspects/${highConcept.aspectName}`}
        >
          {highConcept.aspectName}
        </Link>
      </div>
      <h3>Trouble</h3>
      <div>
        <Link to={`/character/${characterName}/aspects/${trouble.aspectName}`}>
          {trouble.aspectName}
        </Link>
      </div>
      <h3>Background Aspects</h3>
      {backgroundAspects.map((aspect) => (
        <div key={aspect.aspectName}>
          <Link to={`/character/${characterName}/aspects/${aspect.aspectName}`}>
            {aspect.aspectName}
          </Link>
        </div>
      ))}
      <h3>Temporary Aspects</h3>
    </div>
  );
}
