import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import * as firebase from "firebase/app";
import "firebase/auth";

import WithAuth from "./WithAuth";
import WithHeader from "./WithHeader";

import withCharacterData from "./withCharacterData";
import withCompendiumData from "./withCompendiumData";

import MainMenu from "./MainMenu";
import CharacterSelect from "./CharacterSelect";
import CharacterPage from "./CharacterPage";
import CharacterAspectsPage from "./CharacterAspectsPage";
import CharacterAspectExplanation from "./CharacterAspectExplanation";
import Compendium from "./Compendium";
import Login from "./Login";

import { setLoggedInUser } from "./app/auth-slice";

import "./database/fire";

import "./App.css";

const routeList = [
  {
    path: "/character/:characterName/aspects/:aspectName",
    component: withCharacterData(CharacterAspectExplanation),
    name: "Aspect Definition",
  },
  {
    path: "/character/:characterName/aspects",
    component: withCharacterData(CharacterAspectsPage),
    name: "Character Aspects",
  },
  {
    path: "/character/:characterName",
    component: withCharacterData(CharacterPage),
    name: "Character Sheet",
  },
  {
    path: "/character",
    component: CharacterSelect,
    name: "Character Select",
  },
  {
    path: "/compendium/powers/:powerName",
    component: withCompendiumData(Compendium),
    name: "Plus Help: Powers",
  },
  {
    path: "/compendium/skills/:skillName",
    component: withCompendiumData(Compendium),
    name: "Plus Help: Skills",
  },
  {
    path: "/compendium/stunts/:stuntName",
    component: withCompendiumData(Compendium),
    name: "Plus Help: Stunts",
  },
  {
    path: "/compendium/trappings/:trappingName",
    component: withCompendiumData(Compendium),
    name: "Plus Help: Trappings",
  },
  {
    path: "/compendium/powers",
    component: withCompendiumData(Compendium),
    name: "Plus Help: Powers",
  },
  {
    path: "/compendium/skills",
    component: withCompendiumData(Compendium),
    name: "Plus Help: Skills",
  },
  {
    path: "/compendium/stunts",
    component: withCompendiumData(Compendium),
    name: "Plus Help: Stunts",
  },
  {
    path: "/compendium/trappings",
    component: withCompendiumData(Compendium),
    name: "Plus Help: Trappings",
  },
  {
    path: "/compendium",
    component: withCompendiumData(Compendium),
    name: "Plus Help",
  },
  {
    path: "/",
    component: MainMenu,
    name: "Main Menu",
  },
];

// const routeMap = routeList.reduce(
//   (accumulator, routeConfigEntry) => ({
//     ...accumulator,
//     [routeConfigEntry.name]: routeConfigEntry,
//   }),
//   {}
// );

// function ComingSoon() {
//   return <div> Coming Soon </div>;
// }

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((firebaseUserObject) => {
      if (firebaseUserObject) {
        window.user = firebaseUserObject;
        const {
          uid,
          email,
          emailVerified,
          displayName,
          photoURL,
          refreshToken,
        } = firebaseUserObject;
        dispatch(
          setLoggedInUser({
            uid,
            email,
            emailVerified,
            displayName,
            photoURL,
            refreshToken,
          })
        );
      }
    });
  }, [dispatch]);

  return (
    <Router>
      <Switch>
        <Route path="/login">
          <WithHeader path="/login" name="Login">
            <Login />
          </WithHeader>
        </Route>
        {routeList.map((routeConfigEntry) => (
          <Route key={routeConfigEntry.path} path={routeConfigEntry.path}>
            <WithHeader
              path={routeConfigEntry.path}
              name={routeConfigEntry.name}
            >
              <WithAuth>
                <routeConfigEntry.component />
              </WithAuth>
            </WithHeader>
          </Route>
        ))}
      </Switch>
    </Router>
  );
}

export default App;
