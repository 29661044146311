import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import { getActiveCharacter } from "./active-character-slice";

import { deleteStressBox, postStressBox } from "../bob-the-skull-api/commands";

export const healStress = createAsyncThunk(
  "characters/healStress",
  async (payload, thunkAPI) => {
    return await deleteStressBox(payload);
  }
);

export const damageStress = createAsyncThunk(
  "characters/damageStress",
  async (payload, thunkAPI) => {
    return await postStressBox(payload);
  }
);

const charactersSlice = createSlice({
  name: "characters",
  initialState: {},
  reducers: {
    loadCharactersStarted(state, action) {},
    loadCharactersFulfilled(state, action) {
      return { ...state, ...action.payload };
    },
    loadCharacterStarted(state, action) {
      state[action.payload] = { ...state[action.payload], loading: true };
    },
    loadCharacterFulfilled(state, action) {
      state[action.payload.characterName] = action.payload;
      state[action.payload.characterName].loading = false;
    },
    spendFate(state, action) {
      // TODO: Update spreadsheet when this happens
      state[action.payload.characterName].fatePoints = Math.max(
        0,
        state[action.payload.characterName].fatePoints - 1
      );
    },
    resetStressBoxes(state, action) {
      state[action.payload.characterName].stress = [];
    },
  },
  extraReducers: {
    [damageStress.pending]: (state, action) => {
      console.log("damagestress pending", action.meta.arg);
      const {
        characterName,
        stressTrackName,
        stressLevelTaken,
      } = action.meta.arg;
      state[characterName].stress[stressTrackName][stressLevelTaken].network =
        "Pending";
    },
    [damageStress.fulfilled]: (state, action) => {
      console.log("damagestress fulfilled", action.payload);
      const {
        id,
        characterName,
        stressTrackName,
        stressLevelTaken,
      } = action.payload;
      state[characterName].stress[stressTrackName][stressLevelTaken].id = id;
      state[characterName].stress[stressTrackName][stressLevelTaken].network =
        "Fulfilled";
      state[characterName].stress[stressTrackName][
        stressLevelTaken
      ].filled = true;
    },
    [damageStress.rejected]: (state, action) => {
      console.log("damagestress rejected", action.meta.arg);
      const {
        characterName,
        stressTrackName,
        stressLevelTaken,
      } = action.meta.arg;
      state[characterName].stress[stressTrackName][stressLevelTaken].network =
        "Rejected";
    },
    [healStress.pending]: (state, action) => {
      console.log("healstress pending", action.meta.arg);
      const {
        characterName,
        stressTrackName,
        stressLevelTaken,
      } = action.meta.arg;
      state[characterName].stress[stressTrackName][stressLevelTaken].network =
        "Pending";
    },
    [healStress.fulfilled]: (state, action) => {
      console.log("healstress fulfilled", action.payload);
      const {
        characterName,
        stressTrackName,
        stressLevelTaken,
      } = action.meta.arg;
      state[characterName].stress[stressTrackName][stressLevelTaken].id =
        action.payload.id;
      state[characterName].stress[stressTrackName][
        stressLevelTaken
      ].filled = false;
      state[characterName].stress[stressTrackName][stressLevelTaken].network =
        "Fulfilled";
    },
    [healStress.rejected]: (state, action) => {
      console.log("healstress rejected", action.meta.arg);
      console.error(action.error);
      const {
        characterName,
        stressTrackName,
        stressLevelTaken,
      } = action.meta.arg;
      state[characterName].stress[stressTrackName][stressLevelTaken].network =
        "Rejected";
    },
  },
});

const getCharacters = (state) => state.characters;

const getCharacter = createSelector(
  getActiveCharacter,
  getCharacters,
  (activeCharacter, characters) => characters[activeCharacter.characterName]
);

export const getSkills = createSelector(
  getCharacter,
  (character) => character.skills
);

export function level2Label(skillLevel) {
  const labels = {
    0: "Mediocre",
    1: "Average",
    2: "Fair",
    3: "Good",
    4: "Great",
    5: "Superb",
    6: "Fantastic",
    7: "Epic",
    8: "Legendary",
  };
  return labels[skillLevel];
}

const isHighConcept = (aspect) => aspect.aspectType === "High Concept";
const isTrouble = (aspect) => aspect.aspectType === "Trouble";
const isBackgroundAspect = (aspect) => aspect.aspectType === "Background";

export const highConceptSelector = (characterState) =>
  Object.values(characterState.aspects).find(isHighConcept);
export const troubleSelector = (characterState) =>
  Object.values(characterState.aspects).find(isTrouble);
export const backgroundAspectsSelector = (characterState) =>
  Object.values(characterState.aspects).filter(isBackgroundAspect);

export const {
  loadCharactersStarted,
  loadCharactersFulfilled,
  loadCharacterStarted,
  loadCharacterFulfilled,
  spendFate,
} = charactersSlice.actions;
export default charactersSlice.reducer;
