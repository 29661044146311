import { createSlice } from "@reduxjs/toolkit";

const activeCharacterSlice = createSlice({
  name: "activeCharacter",
  initialState: {
    id: 2,
    characterName: "Aleksy",
  },
  reducers: {
    setActiveCharacter(state, action) {
      const { id, characterName } = action.payload;
      state[id] = id;
      state[characterName] = characterName;
    },
  },
});

export const getActiveCharacter = (state) => state.activeCharacter;
export const { setActiveCharacter } = activeCharacterSlice.actions;
export default activeCharacterSlice.reducer;
