import models from "./database";

export async function deleteStressBox({
  characterName,
  stressTrackName,
  stressLevelTaken,
  id,
}) {
  const result = await models["Character Stress"].putById(id, {
    characterName,
    stressTrackName,
    stressLevelTaken,
    healed: true,
  });

  return {
    id: result.id,
    characterName,
    stressTrackName,
    stressLevelTaken,
  };
}

export async function postStressBox({
  characterName,
  stressTrackName,
  stressLevelTaken,
}) {
  let stressDocs;
  try {
    stressDocs = await models["Character Stress"].getByFilter({
      characterName,
    });
    stressDocs = stressDocs.filter((stressDoc) => stressDoc.healed !== true);
    stressDocs = stressDocs.filter(
      (stressDoc) =>
        stressDoc.stressTrackName === stressTrackName &&
        stressDoc.stressLevelTaken === stressLevelTaken
    );
  } catch (e) {
    console.error(e);
    throw e;
  }

  if (stressDocs.length) {
    return {
      id: stressDocs[0].id,
      characterName,
      stressTrackName,
      stressLevelTaken,
    };
  }

  let result = await models["Character Stress"].post({
    characterName,
    stressTrackName,
    stressLevelTaken,
  });

  console.log(result);

  return result;
}
