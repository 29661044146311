import React, { useEffect } from "react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getCharacters } from "./bob-the-skull-api/queries";
import {
  loadCharactersStarted,
  loadCharactersFulfilled,
} from "./app/characters-slice";

import CharacterStub from "./CharacterStub";
import Loading from "./Loading";

import "./CharacterSelect.css";

export default function CharacterSelect() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadCharactersStarted());
    getCharacters().then((characters) => {
      dispatch(loadCharactersFulfilled(characters));
    });
  }, [dispatch]);

  const state = useSelector((state) => state);

  const history = useHistory();

  function goToCharacterPage(characterName) {
    history.push(`/character/${characterName}`);
  }

  return (
    <div className="App">
      <h1>Character Select</h1>
      {state.loading ? (
        <Loading />
      ) : (
        R.ifElse(
          R.identity,
          R.compose(
            R.values,
            R.map((character) => (
              <div
                key={character.characterName}
                className="characterSelect__characterStubContainer"
              >
                <CharacterStub
                  onClick={() => goToCharacterPage(character.characterName)}
                  character={character}
                />
              </div>
            ))
          ),
          R.always(null)
        )(state.characters)
      )}
    </div>
  );
}
