import React from "react";

export default function Rotes({ rotes }) {
  return (
    <div>
      {rotes.map(({ id, roteName, element }) => (
        <div className="Rote" key={id}>
          <div className="Rote__Name">{roteName}</div>
          <div className="Rote__Element">{element}</div>
        </div>
      ))}
    </div>
  );
}
