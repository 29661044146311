import React, { useEffect } from "react";
import * as R from 'ramda';
import { useSelector, useDispatch } from "react-redux";
// import { useParams } from "react-router-dom";

import {
  loadCompendium,
  getCompendiumData,
} from "./app/compendium-slice";

import Loading from "./Loading";

export default function withCompendiumData(WrappedComponent) {
  return function HasCompendiumData() {
    const dispatch = useDispatch();
    const loadCompendiumData = R.compose(dispatch, loadCompendium);
    const state = useSelector(getCompendiumData);

    useEffect(() => {
      loadCompendiumData();
    }, [loadCompendiumData]);

    return !state || state.loading ? (
      <Loading />
    ) : (
      <WrappedComponent />
    );
  };
}
