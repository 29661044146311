import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth-slice";
import characterReducer from "./characters-slice";
import compendiumReducer from "./compendium-slice";
import loadingReducer from "./loading-slice";

export default configureStore({
  reducer: {
    characters: characterReducer,
    compendium: compendiumReducer,
    loading: loadingReducer,
    auth: authReducer,
  },
});

// TODO: cleanup
// Characters: just enough info to display list of characters in menu
// ActiveCharacter: slice containing data about active character sheet
// Navigation: where are we in the app?
// SkillLadder: info about the skill ladder
// Skills: For caching info about skills
// Powers: For caching info about powers
// Stunts: For caching info about stunts
