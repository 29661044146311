import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { loggedInUserSelector, login } from "./app/auth-slice";

import "./MainMenu.css";

export default function MainMenu() {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(loggedInUserSelector);

  return loggedInUser !== null ? (
    <div className="MainMenu">
      <div className="clickable">
        <Link to="/character">Character Select</Link>
      </div>
      <div className="clickable">
        <Link to="/compendium">Plus Help</Link>
      </div>
    </div>
  ) : (
    <div className="MainMenu">
      <div onClick={() => dispatch(login)} className="clickable">
        Log In
      </div>
      <div className="clickable">
        <Link to="/register">Sign Up</Link>
      </div>
    </div>
  );
}
