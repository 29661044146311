import React from "react";

import CharacterSheet from "./CharacterSheet";

export default function CharacterPage({ characterState }) {
  return (
    <div className="App">
      <CharacterSheet character={characterState} />
    </div>
  );
}
