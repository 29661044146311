import React from "react";

import "./WizardItems.css";

export default function WizardItems({ wizardItems }) {
  return (
    <div>
      {wizardItems.map(
        ({
          id,
          slotType,
          slotCost,
          itemName,
          itemDescription,
          shiftStrength,
          usesPerScene,
          shortNotes,
          itemBonus,
        }) => (
          <div className="WizardItem" key={id}>
            <div className="WizardItem__Name">{itemName}</div>
            <div className="WizardItem__SlotType">{slotType}</div>
            <div className="WizardItem__SlotCost">{slotCost}</div>
            <div className="WizardItem__Description">{itemDescription}</div>
            <div className="WizardItem__ShiftStrength">{shiftStrength}</div>
            <div className="WizardItem__Uses">{usesPerScene}</div>
            <div className="WizardItem__ShortNotes">{shortNotes}</div>
            <div className="WizardItem__Bonus">{itemBonus}</div>
          </div>
        )
      )}
    </div>
  );
}
