import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { loggedInUserSelector, login } from "./app/auth-slice";

export default function WithAuth({ children }) {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(loggedInUserSelector);

  return loggedInUser == null ? (
    <div className="MainMenu">
      <div onClick={() => dispatch(login)} className="clickable">
        Log In
      </div>
    </div>
  ) : (
    <>{children}</>
  );
}
